.carousel-item img {
    width: auto !important;
    height: 400px;
}

.slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
}

.screeshot-tab {
    background-color: #5a9db914;
    border-radius: 20px;
    transform: scale(1);
    animation: heartbeat 2s;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}