.contact-icon {
    font-size: 30px;
    background: #6ec1e4;
    text-align: center;
    border-radius: 50%;
    width: 100%;
    padding: 10px;
}

@media only screen and (max-width: 1024px) {
    .contact-icon {
        padding: 0;
    }

    .heading-h1 {
        font-size: 52px;

    }
}

@media only screen and (max-width: 990px) {
    .contact-icon {
        padding: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .contact-icon {
        font-size: 28px;
        padding: 0;
    }
}