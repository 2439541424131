.keypoint-bg {
    background-color: #5a9db914;
}

.card-bg {
    background-image: url(../../../../../public/img/card-bg.jpg) !important;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center center;
    left: 3px;
}

.card-bg:hover {
    transform: scale(1.05);
}

.blue-button {
    background-color: #6ec1e4;
    color: white !important;
    padding: 10px 15px;
    border-radius: 20px;
}

.num-font {
    font-size: 30px;
}

.card-icon {
    width: 100px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    border-color: transparent !important;
    border-radius: 20px !important;
}