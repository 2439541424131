.intro-img {
    width: 80%;
    height: auto;
}

.intro-img-bg {
    background-image: url(../../../../../public/img/intro-bg.jpg);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.8;
    animation: heartbeat-intro 4s infinite;
}

/* @keyframes rotate-bg {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    50% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }

    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
} */

.blue-button {
    background-color: #6ec1e4;
    color: white !important;
    padding: 10px 15px;
    border-radius: 20px;
}

.intro-h1 {
    font-size: 55px;
    font-weight: 700;
    line-height: 1;
}

.intro-h4 {
    color: #6ec1e4;
}

.list-group-item {
    border: transparent;
}

.list-group i.bi.bi-check {
    background: #6ec1e4;
    border-radius: 50%;
    padding: 0px 3px;
    color: white;
}

@keyframes heartbeat-intro {
    0% {
        transform: scale(.95);
    }

    20% {
        transform: scale(1);
    }

    40% {
        transform: scale(.95);
    }

    60% {
        transform: scale(1);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(.95);
    }
}