.choose-img {
    width: auto;
    height: 430px;
    /* -webkit-transform: rotate(0);
    transform: rotate(0); */
    animation: animate-choose 4s infinite;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@keyframes animate-choose {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

.choose-img:hover {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
}

.choose-bg {
    background-color: #6ec1e4;
    padding: 40px;
    border-radius: 20px;
    height: 380px;
}

.app-store {
    width: 180px;
    height: 55px;
    border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
    .choose-bg {
        height: auto;
    }
}

@media only screen and (max-width:600px) {
    .choose-img {
        width: 100%;
        height: auto;
    }
}