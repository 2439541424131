.context {
    width: 100%;
    position: absolute;
    top: 50vh;

}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area {
    background: #6ec1e4;
    /* background: -webkit-linear-gradient(to left, #6ec1e4, #6ec1e4); */
    width: 100%;
    height: 700px;
    /* height: 100vh; */
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.banner-img {
    width: auto;
    height: auto;
    z-index: 9;
    position: relative;
    animation: banner-rotate 15s;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@keyframes banner-rotate {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    50% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }

    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
}

.heading-h1 {
    font-size: 65px;
    font-weight: 700;
    line-height: 1;
}

@media only screen and (max-width: 990px) {
    .area {
        height: 100vh;
    }
}

@media only screen and (max-width: 600px) {
    .banner-img {
        width: 200px;
    }
}