.nav-button {
    background-color: white;
    color: #6ec1e4 !important;
    padding: 10px 15px;
    border-radius: 20px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.0rem;
    padding-left: 1.0rem;
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(0, 0, 0);
    font-weight: 400;
    font-family: 'roboto';
    /* font-family: monospace; */
}

.fixed-top {
    height: 95px;
    background: #6ec1e4;
}

@media only screen and (max-width: 990px) {
    div#responsive-navbar-nav {
        background: white;
    }
}